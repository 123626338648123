import React from 'react';
import { Button, Container, Grid, Hidden, Typography } from '@material-ui/core';
import useStyles from './Referrals-program.style';
import foundations from '../../../../images/Benefits/referrals/foundations.png';
import referralsImage from '../../../../images/Benefits/referrals/referrals.png';
import TermsModal from './TermsModal';
import Foundations from './foundations/Foundations';

const ReferralsView = () => {
  const handleAnchorClick = () => {
    localStorage.setItem('link', 'referral');
  };
  const [showModal, setShowModal] = React.useState(false);
  const showModalComponent = (boolean: boolean) => {
    setShowModal(boolean);
  };

  const classes = useStyles();
  return (
    <React.Fragment>
      <section className={classes.root}>
        <Container className={classes.container}>
          <div className={classes.benefitsTitle}>
            {showModal && <TermsModal showModalComponent={showModalComponent}></TermsModal>}
            <strong>Beneficios</strong>
          </div>
          <Grid className={classes.gridContainer} container>
            <Grid className={classes.firstSubSection} item xs={12} md={8}>
              <Grid className={classes.firstSubSectionTitle} item xs={12}>
                <h1 className={classes.title}>
                  ¡Conoce nuestro programa <br /> de donaciones y sé <br />
                  <span>el motor de cambio!</span>
                </h1>
              </Grid>
              <div className={classes.referralsButtonDiv}>
                <Button
                  href={`${process.env.GATSBY_URL_NEW_FRONT}/referralprogram/home`}
                  className={classes.referralsButton}
                >
                  ¡Lo quiero!
                </Button>
              </div>
              <Hidden mdDown>
                <Grid className={classes.subtitle} item xs={12}>
                  <h2>
                    En <strong>Currencybird</strong> creemos que es importante
                    <br /> involucrarnos en la <strong> construcción y colaboración</strong>
                    <br /> <strong>de una sociedad más amable.</strong>
                  </h2>
                </Grid>
              </Hidden>
              <Hidden mdDown>
                <Grid className={classes.subtitle} item xs={12}>
                  <h2>
                    Es por esto que lanzamos un <strong>convenio con las fundaciones </strong>
                    <br /> Servicio Jesuita a Migrantes, Corporación por la Niñez
                    <br /> y Fundación Mi Parque para apoyar distintas casusas que
                    <br /> <strong>promueven el bienestar de la sociedad a través</strong>
                    <br />{' '}
                    <strong>
                      de un programa de donaciones <sup>(1)</sup>.
                    </strong>
                  </h2>
                </Grid>
              </Hidden>
            </Grid>
            <Hidden mdUp>
              <Grid className={classes.subtitle} item xs={12}>
                <Typography>
                  Con el fin de colaborar por la construcción de una{' '}
                  <strong>sociedad más amable</strong>, lanzamos un convenio con{' '}
                  <strong>fundaciones</strong> que apoyan distintas casusas través de un{' '}
                  <strong>programa de donaciones</strong> (1).
                </Typography>
              </Grid>
              <Grid className={classes.imgTitle} item xs={12}>
                <Typography className={classes.imgTitle} component="p">
                  <strong>¡Conoce las fundaciones beneficiarias!</strong>
                </Typography>
              </Grid>
              <Grid className={classes.foundations} item xs={12} md={4}>
                <Foundations></Foundations>
              </Grid>
            </Hidden>
            <Hidden mdDown>
              <Grid item xs={12} md={4}>
                <img className={classes.referralsImage} src={referralsImage} />
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </section>
      <Hidden mdDown>
        <section className={classes.foundationsSection} id="inicio">
          <Container>
            <Grid className={classes.gridContainer} container>
              <Grid item xs={12} md={12}>
                <Grid className={classes.firstSubSectionTitle} item xs={12}>
                  <h2 className={classes.foundationsTitle}>
                    Estas son nuestras fundaciones beneficiarias:
                  </h2>
                </Grid>
                <Grid item xs={12} md={12}>
                  <img className={classes.foundationsImg} src={foundations} />
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </section>
      </Hidden>
      <section className={classes.howWorksRoot} id="inicio">
        <Container>
          <Grid container>
            <Grid item xs={12} lg={2}></Grid>
            <Grid className={classes.firstSubSectionTitle} item xs={12} md={10}>
              <h2 className={classes.howWorksTitle}>¿Cómo participar?</h2>
              <div>
                <h3 className={classes.howWorksList}>
                  1.- Si no tienes cuenta en nuestro sitio, regístrate en{' '}
                  <a
                    className={classes.howWorksLink}
                    href={`${process.env.GATSBY_SITE_URL}/user/register`}
                  >
                    <strong>www.currencybird.cl. </strong>
                  </a>
                </h3>
                <h3 className={classes.howWorksList}>
                  2.- Ingresa a tu sesión y pincha en el costado superior derecho{' '}
                  <a
                    className={classes.howWorksLinkInvite}
                    onClick={handleAnchorClick}
                    href={`${process.env.GATSBY_URL_NEW_FRONT}/referralprogram/dashboard`}
                  >
                    <strong>“Invita a un amig@”</strong>
                  </a>
                  .
                </h3>
                <h3 className={classes.howWorksList}>
                  3.- Copia el link que generamos (te aparecerá en la pantalla) y envíaselo a las
                  personas que
                  <br></br>
                  quieras (ellos serán tus referidos).
                </h3>
                <h3 className={classes.howWorksList}>
                  4.- Tus referidos deberán registrarse como cliente en nuestro sitio web a través
                  del link <br /> enviado por ti y realizar una transferencia de dinero al exterior.
                </h3>
                <h3 className={classes.howWorksList}>
                  5.- Por referente y referido, <strong>regalaremos $8.000 CLP</strong> para que lo
                  donen a cualquiera de las
                  <br /> tres fundaciones de nuestro convenio. Cada quien elige la fundación que
                  quiera.
                </h3>
              </div>
              <div className={classes.howWorksButtonDiv}>
                <Button
                  href={`${process.env.GATSBY_SITE_URL}/user/register`}
                  className={classes.howWorksButton}
                >
                  ¡Lo quiero!
                </Button>
              </div>
            </Grid>
            <div>
              <h3 className={classes.howWorksTerms}>
                (1) Las donaciones que se realicen bajo el programa de referidos están sujetas a la
                Ley N* 21.440,
                <br /> por lo tanto, estarán a nombre de CurrencyBird SpA. Sin embargo, se le
                entregará a cada donante
                <br /> un certificado simbólico emitido por la fundación correspondiente.
              </h3>
              <div className={classes.termsDiv}>
                <button
                  className={classes.howWorksTermsButton}
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <h3 className={classes.howWorksTermsButton}>
                    Para más información, ver{' '}
                    <span className={classes.howWorksTermsButtonUnderline}>
                      términos y condiciones
                    </span>{' '}
                    del programa
                  </h3>
                </button>
              </div>
            </div>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ReferralsView;
